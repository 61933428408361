
import { Component, Prop, Vue } from 'vue-property-decorator';

import { getComponent, getView, sleep } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import gptModule from '@/store/modules/gptModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    TasqAiCommentEntry: () => getComponent('gpt/TasqAICommentEntry'),
    TasqAiAudioEntry: () => getComponent('gpt/TasqAIAudioEntry'),
  },
})
export default class GroupList extends Vue {
  @Prop({ type: Object, required: true, default: () => {} }) group?: any;

  @Prop({ type: Boolean, required: true, default: false }) accordianState?: any;

  @Prop({ type: Boolean, required: true, default: false })
  showDataEntryState?: any;

  @Prop({ type: Boolean, required: false, default: false })
  manualGroup?: any;

  @Prop({ type: String, required: true, default: 'In line View' })
  groupViewState?: any;

  @Prop({ type: String, required: true, default: false }) groupType?: any;

  searchQuery = '';

  jsonFields = {
    'Well name': 'wellName',
    Deferment: 'defermentValue',
    Route: 'route',
    Team: 'team',
    CycleTime: 'dateAgoText',
  };


  get isSelectedWell() {
    return gptModule.selectedAiGroupWellsObj.filter(s => s.typeOfData === this.group.query ).length === this.group.wells.length;
  }

  toggleCheckbox() {
    const isSelected = this.isSelectedWell;
    gptModule.updateGptAiGroupWellsByType(this.group.query)
    if(!isSelected) {
      const allData =  this.group.wells.map(w => {
        return  {"wellName": w.wellName, "typeOfData": this.group.query}
      })
      gptModule.addGptAiGroupWellsByType(allData)
    }

  }

  changeGroupView(query: string, view: string) {
    this.$emit('change-group-view', query, view);
  }

  get settingsOptions() {
    if (this.group && this.group.settings && this.group.settings.is_published) {
      return ['Delete'];
    }
    return ['Publish', 'Delete'];
  }

  get isGroupOwner() {
    return (
      this.group.created_by === workflowModule.user.email &&
      this.groupType !== 'audio_entry' &&
      !this.manualGroup
    );
  }

  sumByKey(array, key = 'defermentValue') {
    const sum = array.reduce((acc, obj) => {
      // Ensure the key exists in the object and is a number
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'number') {
        acc += obj[key];
      }
      return acc;
    }, 0);
    return parseFloat(sum.toFixed(0)).toLocaleString();
  }

  hideAudioEntryUI = false;
  async refreshAudioEntryPopup() {
    this.hideAudioEntryUI = true;
    this.$nextTick(() => {
      this.hideAudioEntryUI = false;
    });
    await sleep(3500);
    await this.$emit('refresh-last-actions');
  }

  performAction(action: string) {
    console.log(action);
    console.log(this.group);
    this.$emit('perform-action', {
      action: action.toLowerCase(),
      group: this.group,
    });
  }
}
